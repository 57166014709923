<template>
  <div class="container">
    <template>
      <jqdjR1></jqdjR1>
      <jqdjR2></jqdjR2>
    </template>
  </div>
</template>

<script>
import jqdjR1 from './components/r1.vue'
import jqdjR2 from './components/r2.vue'

export default {
  components: { jqdjR1, jqdjR2 },
  data() {
    return {}
  },
  computed: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: space-between;
  align-content: space-between;
}
</style>
