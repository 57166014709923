<template>
    <div class="containerR2">
        <ek-panel title="联建活动分类统计">
            <div class="box">
                <div>联建活动分类总数: {{ total }}</div>
                <div class="chart">
                    <ek-chart :options="options"
                              :animation="true"
                              :animationData="options.series[0].data" />
                </div>
                <div class="table">
                    <div class="table_header lucencys">
                        <span>序号</span>
                        <span>活动标题</span>
                        <span>单位名称</span>
                        <span>人数</span>
                        <span>时间</span>
                    </div>
                    <div class="t-list">
                        <swiper ref="orderSwiper"
                                :options="swiperOptions">
                            <swiper-slide v-for="(items, index) in tableData"
                                          :key="index">
                                <div :class="['item', index == 1 ? 'lucency' : '']"
                                     v-for="(item, index) in items"
                                     :key="index">
                                    <span>{{ item.num }}</span>
                                    <span>{{ item.title }}</span>
                                    <span>{{ item.name }}</span>
                                    <span>{{ item.resiNum }}</span>
                                    <span>{{ item.date }}</span>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </ek-panel>
    </div>
</template>
  
  <script>
import { requestPostBi } from '@/utils/requestMiddleground'
import axios from 'axios';

export default {
    components: {},
    data() {
        return {
            tableData: [], //列表数据
            swiperOptions: {
                direction: 'vertical',
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                speed: 900,
                loop: false,
            },
            options: {
                tooltip: {
                    trigger: 'item',
                    confine: true,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    formatter: params => {
                        let el = ''
                        el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params.name}：</span>
                        <span class="v">${params.value}</span>
                      </div>
                    </div>`
                        return el
                    }
                },
                legend: [],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['75%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        itemStyle: {
                            borderColor: '#4A4D4D',
                            borderWidth: 2,
                        },
                        emphasis: {
                            label: {
                                formatter: `{b} \n {d}%`,
                                show: true,
                                fontSize: 18,
                                borderWidth: 0,
                                color: '#fff',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [],
                    },
                ],
            },
            total: '',
        }
    },
    mounted() {
        this.getTableList()
        this.getPeiList()
    },
    methods: {
        getPeiList() {
            const par = {
                "PartyJointConstructionUnitActivityAnalysises[]": {
                    "PartyJointConstructionUnitActivityAnalysis": {
                        "@column": "CATEGORY_NAME,CATEGORY_NUM"
                    }
                },
                "query": 2,
                "count": 0
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        res['PartyJointConstructionUnitActivityAnalysises[]'].forEach(f => {
                            if (f.PartyJointConstructionUnitActivityAnalysis.CATEGORY_NAME == "TOTAL") {
                                this.total = f.PartyJointConstructionUnitActivityAnalysis.CATEGORY_NUM
                            }
                        })
                        let arr = res['PartyJointConstructionUnitActivityAnalysises[]'].map((item) => ({
                            name: item.PartyJointConstructionUnitActivityAnalysis.CATEGORY_NAME,
                            value: item.PartyJointConstructionUnitActivityAnalysis.CATEGORY_NUM,
                        })).filter(f => {
                            return f.name != "TOTAL"
                        })
                        this.options.series[0].data = arr
                        this.options.legend = [
                            {
                                data: arr.map((item) => item.name).splice(0, 3),
                                orient: 'vertical',
                                left: '0',
                                top: '10%',
                                itemGap: 15,
                                formatter: (name) => {
                                    let val = ''
                                    for (let i = 0, l = arr.length; i < l; i++) {
                                        if (arr[i].name == name) {
                                            val = arr[i].value
                                        }
                                    }
                                    return `${name}` + `  ` + `{val|${val}} `
                                },
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 18,
                                    rich: {
                                        val: {
                                            fontSize: 25,
                                        },
                                    },
                                },
                            },
                            {
                                data: arr.map((item) => item.name).splice(3, 3),
                                orient: 'vertical',
                                top: '10%',
                                left: '30%',
                                itemGap: 15,
                                formatter: (name) => {
                                    let val = ''
                                    for (let i = 0, l = arr.length; i < l; i++) {
                                        if (arr[i].name == name) {
                                            val = arr[i].value
                                        }
                                    }
                                    return `${name}` + `  ` + `{val|${val}} `
                                },
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 18,
                                    rich: {
                                        val: {
                                            fontSize: 25,
                                        },
                                    },
                                },
                            },
                        ]
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTableList() {
            const par = {
                "PartyJoinConstructionUnitActivitys[]": {
                    "PartyJoinConstructionUnitActivity": {
                        "@column": "ID,ACTIVITY_NAME,UNIT_NAME,JOIN_NUM,ACTIVITY_DATE"
                    }
                },
                "count": 0,
                "query": 2
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        let tempList = res['PartyJoinConstructionUnitActivitys[]'].map((item, index) => ({
                            num: index + 1,
                            name: item.PartyJoinConstructionUnitActivity.UNIT_NAME,
                            title: item.PartyJoinConstructionUnitActivity.ACTIVITY_NAME,
                            date: item.PartyJoinConstructionUnitActivity.ACTIVITY_DATE.split("T")[0],
                            resiNum: item.PartyJoinConstructionUnitActivity.JOIN_NUM
                        }))
                        this.tableData = this.groupList(tempList, 3)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    computed: {},
}
</script>
  
  <style scoped lang="scss">
.containerR2 {
    @include flex(column);
    width: 100%;
    right: 0;
    height: 47%;
    animation: fadeInRight 1s ease;
    padding: 5px 50px 18px 0px;
    .box {
        @include flex(column);
        height: 100%;
        padding: 10px 30px;
        box-sizing: border-box;
        > :nth-child(1) {
            margin-top: 10px;
        }
    }
    .chart {
        width: calc(100% - 20px);
        height: 220px;
    }
    .table {
        @include flex(column);
        .lucency {
            background: rgba(32, 39, 56, 0.5);
        }
        .lucencys {
            background: rgba(32, 39, 56, 0.8);
        }
        .table_header {
            @include flex();
            justify-content: space-around;
            text-align: center;
            span {
                height: 46px;
                line-height: 46px;
            }
            :nth-child(1) {
                width: 50px;
            }
            :nth-child(2) {
                flex: 1;
            }
            :nth-child(3) {
                width: 200px;
            }
            :nth-child(4) {
                width: 100px;
            }
            :nth-child(5) {
                width: 150px;
            }
        }

        .t-list {
            @include flex(column);
            flex: 1;
            .swiper-container {
                height: 153px;
                width: 100%;
            }
            .item {
                @include flex();
                align-items: center;
                height: 50px;
                justify-content: space-around;
                background: rgba($color: #202738, $alpha: 0.5);
                cursor: pointer;
                span {
                    text-align: center;
                }
                > :nth-child(1) {
                    width: 50px;
                }
                > :nth-child(2) {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                > :nth-child(3) {
                    width: 200px;
                }
                > :nth-child(4) {
                    width: 100px;
                }
                > :nth-child(5) {
                    width: 150px;
                }
            }
        }
    }
    .lucency {
        background: rgba(32, 39, 56, 0.8) !important;
    }
}
</style>
  