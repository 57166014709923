<template>
  <div class="container">
    <template>
      <jqdjL1></jqdjL1>
      <jqdjL2></jqdjL2>
    </template>
  </div>
</template>

<script>
import jqdjL1 from './components/l1.vue'
import jqdjL2 from './components/l2.vue'

export default {
  components: { jqdjL1, jqdjL2 },
  data() {
    return {}
  },
  computed: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: space-between;
  align-content: space-between;
}
</style>
