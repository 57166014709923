<template>
  <div class="container">
    <ek-legend />
  </div>
</template>
  
  <script>
export default {}
</script>
  
  <style scoped lang="scss">
.container {
  @include flex(column);
}
.total {
  margin-top: 10px;
}
.ek-legend {
  background: none !important;
}
</style>
  